import React, { useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import Close from '../assets/img/close-icon-white.svg';
import { navigate } from 'gatsby';
import { getLanguageNames } from '../utils/isoLang';

const LanguageMenuMobile = ({ onClose, isOpen, currentLang, languages }) => {
  const [languageNames, setLanguageNames] = useState();
  const [currentLangName, setCurrentLangName] = useState('');

  useEffect(() => {
    if (!languages) {
      return;
    }
    const langNameArray = [];
    languages.forEach((lang) => {
      langNameArray.push(getLanguageNames(lang));
    });
    setLanguageNames(langNameArray);
  }, [languages]);

  useEffect(() => {
    if (!currentLang) {
      return;
    }
    const langName = getLanguageNames(currentLang);
    setCurrentLangName(langName);
  }, [currentLang]);

  const handleClick = (index) => {
    onClose();
    if (languages[index] === 'en') {
      navigate('/');
    } else {
      navigate(`/${languages[index]}`);
    }
  };

  return (
    <>
      {isOpen && languageNames?.length > 0 && (
        <div className="language-menu-container-mobile" onClick={onClose}>
          <Fade bottom>
            <div
              className="language-menu-inner-container-mobile"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="language-menu-inner-top-container subtitle">
                <div style={{ pointerEvents: 'none' }}>Language:</div>
                <div>
                  <img src={Close} alt="" onClick={onClose} />
                </div>
              </div>
              {languageNames?.map((language, index) => {
                return (
                  <div
                    className={`subtitle language-option-mobile ${
                      language === currentLangName ? 'selected' : ''
                    }`}
                    onClick={() => handleClick(index)}
                    key={index}
                  >
                    {language}
                  </div>
                );
              })}
            </div>
          </Fade>
        </div>
      )}
    </>
  );
};

export default LanguageMenuMobile;
