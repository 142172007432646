import React, { useEffect, useState } from 'react';
import '../utils/styles/styles.sass';
import Footer from './Footer';
import LayoutBackground from './LayoutBackground';
import CustomCursor from './CustomCursor';
import LoadingSection from '../sections/LoadingSection';
import useBreakpoint from '../utils/useBreakpoint';
import FooterMobile from './FooterMobile';
import { graphql, StaticQuery } from 'gatsby';
import Header from './Header';
import HeaderMobile from './HeaderMobile';
import { getTranslatedHeaderData } from '../utils/headerData';
import ErrorBoundary from './ErrorBoundary';
import ReactGA from 'react-ga';

const Layout = ({ children, uri }) => {
  const [animationDidEnd, setAnimationDidEnd] = useState(false);
  const [allLanguages, setAllLanguages] = useState();
  const [currentLang, setCurrentLang] = useState();
  const [headerData, setHeaderData] = useState();
  const [blogHeaderData, setBlogHeaderData] = useState();
  const [footerData, setFooterData] = useState();
  const [homeData, setHomeData] = useState();
  const [verticalData, setVerticalData] = useState();

  useEffect(() => {
    ReactGA.initialize('UA-175243912-1');
    ReactGA.pageview(uri);

    const langKey = uri.split('/')[1];
    if (langKey && langKey.length === 2) {
      setCurrentLang(langKey);
    } else {
      setCurrentLang('en');
    }
  }, [uri]);
  const isMobile = useBreakpoint('sm', false);

  return (
    <StaticQuery
      query={graphql`
        query footerQuery {
          prismicFooter {
            alternate_languages {
              lang
              document {
                ... on PrismicFooter {
                  id
                  data {
                    contact_info_group {
                      contact_info_text
                      contact_info_image {
                        url
                      }
                    }
                    title
                    details {
                      detail_text
                      detail_image {
                        url
                      }
                    }
                    text_input_placeholder
                    social_group {
                      social_image {
                        url
                      }
                      social_link {
                        url
                      }
                    }
                    espa_logo {
                      url
                    }
                    espa_pdf {
                      url
                    }
                    get_the_app_group {
                      button {
                        document {
                          ... on PrismicIconButton {
                            id
                            data {
                              icon {
                                url
                              }
                              link {
                                url
                              }
                              text
                            }
                          }
                        }
                      }
                    }
                    button_text
                  }
                }
              }
            }
            lang
            data {
              contact_info_group {
                contact_info_image {
                  url
                }
                contact_info_text
              }
              title
              details {
                detail_image {
                  url
                }
                detail_text
              }
              text_input_placeholder
              social_group {
                social_image {
                  url
                }
                social_link {
                  url
                }
              }
              espa_logo {
                url
              }
              espa_pdf {
                url
              }
              get_the_app_group {
                button {
                  document {
                    ... on PrismicIconButton {
                      id
                      data {
                        icon {
                          url
                        }
                        text
                        link {
                          url
                        }
                      }
                    }
                  }
                }
              }
              button_text
            }
          }
          prismicHeader {
            alternate_languages {
              lang
              document {
                ... on PrismicHeader {
                  id
                  data {
                    header_tabs {
                      header_tab
                    }
                    logo {
                      url
                    }
                    logo_mobile {
                      url
                    }
                    button_text
                    button_link {
                      url
                    }
                  }
                }
              }
            }
            data {
              header_tabs {
                header_tab
              }
              logo {
                url
              }
              logo_mobile {
                url
              }
              button_text
              button_link {
                url
              }
            }
            lang
          }
          prismicBlogSections {
            lang
            data {
              sections_group {
                section
              }
            }
            alternate_languages {
              lang
              document {
                ... on PrismicBlogSections {
                  data {
                    sections_group {
                      section
                    }
                  }
                }
              }
            }
          }
          prismicVerticalSectionText {
            lang
            data {
              loading_left_text
              loading_right_text
              left_title
              right_section_title_group {
                right_section_title
              }
            }
            alternate_languages {
              lang
              document {
                ... on PrismicVerticalSectionText {
                  id
                  data {
                    loading_left_text
                    loading_right_text
                    left_title
                    right_section_title_group {
                      right_section_title
                    }
                  }
                }
              }
            }
          }
          prismicHomePage {
            lang
            data {
              page_name
            }
            alternate_languages {
              lang
              document {
                ... on PrismicHomePage {
                  id
                  data {
                    page_name
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        if (!data) return '';

        useEffect(() => {
          const getData = getTranslatedHeaderData(data.prismicHeader);
          const getBlogData = getTranslatedHeaderData(data.prismicBlogSections);
          const getVerticalData = getTranslatedHeaderData(
            data.prismicVerticalSectionText
          );
          const getFooterData = getTranslatedHeaderData(data.prismicFooter);
          const getHomeData = getTranslatedHeaderData(data.prismicHomePage);
          if (currentLang) {
            setFooterData(getFooterData[currentLang]);
            setHeaderData(getData[currentLang]);
            setBlogHeaderData(getBlogData[currentLang]);
            setVerticalData(getVerticalData[currentLang]);
            setHomeData(getHomeData[currentLang]);
          }
          const languagesArray = [];
          languagesArray.push(data.prismicHeader.lang.split('-')[0]);
          data.prismicHeader.alternate_languages.forEach((item) => {
            languagesArray.push(item.lang.split('-')[0]);
          });
          setAllLanguages(languagesArray);
        }, [currentLang]);

        useEffect(() => {
          if (typeof document !== 'undefined') {
            if (!animationDidEnd) {
              document.body.style.overflowY = 'hidden';
            } else {
              document.body.style.overflowY = 'initial';
            }
          }
        }, [animationDidEnd]);

        return (
          <ErrorBoundary>
            <LoadingSection
              data={verticalData}
              handleAnimation={() => setAnimationDidEnd(true)}
            />
            <main
              style={{
                minHeight: '100vh',
                position: 'relative',
                overflowX: isMobile ? 'hidden' : null,
              }}
            >
              {isMobile ? (
                <HeaderMobile
                  data={headerData}
                  currentLang={currentLang}
                  uri={uri}
                  blogData={blogHeaderData}
                  languages={allLanguages}
                  homeHeader={homeData?.page_name}
                />
              ) : (
                <Header
                  languages={allLanguages}
                  uri={uri}
                  data={headerData}
                  blogData={blogHeaderData}
                  currentLang={currentLang}
                />
              )}
              <LayoutBackground uri={uri} lang={currentLang} data={verticalData} />
              <CustomCursor />
              {children}
              {isMobile ? (
                <FooterMobile data={footerData} lang={currentLang} />
              ) : (
                <Footer data={footerData} lang={currentLang} />
              )}
            </main>
          </ErrorBoundary>
        );
      }}
    />
  );
};

export default Layout;
