import React from 'react';
import CargeButton from './CargeButton';
import Background from '../assets/img/footer-background.svg';
import { navigate } from 'gatsby';
import ReactGA from 'react-ga';

const FooterMobile = ({ data, lang }) => {
  const handleDetailClick = (index) => {
    const locale = lang === 'en' ? '' : '/' + lang;
    switch (index) {
      case 0:
        navigate(`${locale}/work-with-us`);
        break;
      case 1:
        navigate(`${locale}/help-center`);
        break;
      case 2:
        navigate(`${locale}/privacy`);
        break;
      case 3:
        navigate(`${locale}/tos`);
        break;
      case 4:
        navigate(`${locale}/cookies`);
        break;
      case 5:
        navigate(`${locale}/disclaimer`);
        break;
      default:
        break;
    }
  };

  const handleGa = (index) => {
    if (index === 0) {
      ReactGA.ga('send', 'event', 'Download', 'click', 'App Store 2');
      return;
    }
    ReactGA.ga('send', 'event', 'Download', 'click', 'Google Play 2');
  };

  return (
    <div
      style={{
        backgroundColor: '#110935',
        minHeight: '145vh',
        display: 'flex',
        scrollSnapAlign: 'start',
        paddingTop: 72,
      }}
      id="footer-section-container"
    >
      <div
        className="footer-main-container-mobile container-xl"
        style={{
          backgroundImage: `url(${Background})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: '50% 20%',
          backgroundSize: '60vh',
        }}
      >
        <div className="footer-top-container-mobile">
          <div className="footer-contact-container-mobile">
            {data?.contact_info_group?.map((item, index) => (
              <div className="footer-contact-mobile" key={index}>
                <img
                  src={item.contact_info_image.url}
                  style={{ marginRight: 8 }}
                  alt=""
                />
                <div
                  className="body color-white"
                  style={{ fontSize: 14, width: '70%', textAlign: 'left' }}
                >
                  {item.contact_info_text}
                </div>
              </div>
            ))}
          </div>
          <div className="footer-social-container-mobile">
            {data?.social_group?.map((item, index, arr) => {
              return (
                <a
                  href={item.social_link?.url}
                  className={index < arr.length - 1 ? 'footer-social' : null}
                  key={index}
                >
                  <img src={item.social_image?.url} alt={''} />
                </a>
              );
            })}
          </div>
        </div>
        <div className="footer-middle-container-mobile">
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
            }}
          >
            {data &&
              data.title &&
              data.title.split('\n').map((item, index) => (
                <div
                  className={`h2 color-white ${index === 0 ? 'mb-0' : ''}`}
                  key={index}
                >
                  {item}
                </div>
              ))}
          </div>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              flex: 1.2,
            }}
          >
            {data?.get_the_app_group?.map((item, index) => {
              return (
                <CargeButton
                  className={'background-text-dark'}
                  outlined
                  link={item.button?.document?.data?.link?.url}
                  icon={item.button?.document?.data?.icon?.url}
                  iconStyle={{ width: 15, height: 15 }}
                  style={{
                    color: 'white',
                    borderRadius: 12,
                    padding: 22,
                    marginBottom: 12,
                    width: '60%',
                  }}
                  onClick={() => handleGa(index)}
                  key={index}
                >
                  {item.button?.document?.data?.text}
                </CargeButton>
              );
            })}
            <a href={data?.espa_pdf?.url} target="_blank" rel="noreferrer">
              <img src={data?.espa_logo?.url} alt="" style={{ width: 172 }} />
            </a>
          </div>
        </div>
        <div className="footer-bottom-container-mobile">
          <div className="footer-bottom-inner-container-mobile">
            <div className="footer-text-container-mobile">
              <div className="footer-text-input">
                <input
                  className="body color-white footer-mobile-input"
                  placeholder="Enter you email"
                />
              </div>
              <CargeButton
                style={{
                  backgroundColor: '#00A499',
                  height: 54,
                  width: '100%',
                }}
              >
                <div className="body color-white" style={{ fontSize: 14 }}>
                  {data?.button_text}
                </div>
              </CargeButton>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-column flex-1 justify-content-center"
          style={{ marginTop: '10px' }}
        >
          {data?.details.map((item, index) => {
            return (
              <div
                className="d-flex align-items-center"
                style={{ marginRight: 64, marginBottom: 16 }}
                key={index}
                onClick={() => handleDetailClick(index)}
              >
                <img
                  src={item.detail_image?.url}
                  alt=""
                  style={{ marginRight: 6, marginLeft: 3 }}
                />
                <div
                  className="body color-white"
                  style={{ whiteSpace: 'nowrap', fontSize: 14 }}
                >
                  {item.detail_text}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FooterMobile;
