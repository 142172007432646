import React, { useEffect, useRef } from 'react';
import Background from '../assets/img/loading-background.png';
import Logo from '../assets/img/carge-logo-2.svg';
import { TimelineMax, gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';

gsap.registerPlugin(CSSPlugin);

const LoadingSection = ({ handleAnimation, data }) => {
  const blackBack = useRef();
  const loadingPage = useRef();
  const blackBackground = useRef();

  useEffect(() => {
    if (!blackBack.current && !loadingPage.current) {
      return;
    }
    const tl1 = new TimelineMax();
    tl1.set(blackBack?.current, { scaleX: 0 });
    const timeout = setTimeout(() => {
      const duration = 2;
      tl1
        .to(blackBack?.current, { scaleX: 1, transformOrigin: 'right' })
        .duration(duration)
        .add(() => handleAnimation())
        .set(blackBackground?.current, { autoAlpha: 0 })
        .set(loadingPage?.current, { autoAlpha: 0 })
        .to(blackBack?.current, { scaleX: 0, transformOrigin: 'left' })
        .duration(duration);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [blackBack, loadingPage]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{
            height: '100vh',
            backgroundImage: `url(${Background})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1100,
          }}
          ref={loadingPage}
        >
          <div
            className="paragraph color-white"
            style={{
              position: 'absolute',
              writingMode: 'vertical-rl',
              top: '50%',
              right: 80,
              transform: 'rotate(180deg) translateY(50%)',
            }}
          >
            {data && data.loading_right_text}
          </div>
          <div
            className="paragraph color-white"
            style={{
              position: 'absolute',
              writingMode: 'vertical-rl',
              top: '50%',
              left: 80,
              transform: 'rotate(180deg) translateY(50%)',
            }}
          >
            {data && data.loading_left_text}
          </div>
          <img src={Logo} alt="" style={{ width: 114, marginBottom: 40 }} />
          <div className="loading-bar-container">
            <div className="loading-bar" />
          </div>
        </div>
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 1000,
            height: '100vh',
            width: '100vw',
            background: '#0A0B21',
          }}
          ref={blackBackground}
        />
        <div
          ref={blackBack}
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 10000,
            height: '100vh',
            width: '100vw',
            background: '#0A0B21',
          }}
        />
      </div>
    </>
  );
};

export default LoadingSection;
