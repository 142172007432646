export const getTranslatedHeaderData = (data) => {
  if (!data) {
    return;
  }
  let groupedData = {};
  groupedData[data.lang.split('-')[0]] = data.data;
  if (data.alternate_languages) {
    data.alternate_languages.forEach((item) => {
      groupedData[item.lang.split('-')[0]] = item.document.data;
    });
  }
  return groupedData;
};
