import React from 'react';

const CargeButton = ({
  children,
  outlined,
  icon,
  iconStyle,
  className,
  link,
  ...rest
}) => {
  if (!children) {
    return '';
  }
  return (
    <a
      className={`carge-button-container a-tag-no-style ${
        outlined ? 'outlined' : ''
      } ${className}`}
      href={link}
      // target="_blank"
      {...rest}
    >
      {icon && <img src={icon} alt="" style={{ marginRight: 8, ...iconStyle }} />}
      <div className="paragraph">{children}</div>
    </a>
  );
};

export default CargeButton;
