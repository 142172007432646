const React = require('react');
const Layout = require('./src/components/Layout').default;
const ReactDOM = require('react-dom');

exports.replaceHydrateFunction = () => {
  return (element, container, callback) => {
    ReactDOM.render(element, container, callback);
  };
};

exports.wrapPageElement = ({ element, props }) => {
  //To avoid double wrapping the /404 page; remove if unnecessary
  if (element && element.key === '/404.html') {
    return element;
  }

  return <Layout {...props}>{element}</Layout>;
};
