import React, { useEffect, useRef, useState } from 'react';
import '../utils/styles/styles.sass';
import Thunder from '../assets/img/thunder.svg';
import { gsap, Power3, TimelineMax } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useBreakpoint from '../utils/useBreakpoint';

gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(ScrollTrigger);

const boxWidth = 28;

const LayoutBackground = ({ uri, lang, data }) => {
  const textContainerRight = useRef();
  const [rightCounter, setRightCounter] = useState(0);
  const [isHome, setIsHome] = useState();
  const isMobile = useBreakpoint('sm', false);

  useEffect(() => {
    if (uri === '/' || uri === `/${lang}`) {
      setIsHome(true);
    } else {
      setIsHome(false);
    }
  }, [uri, lang]);

  useEffect(() => {
    if (!isHome) {
      return;
    }
    const tl = new TimelineMax();
    tl.to(textContainerRight.current, {
      x: -boxWidth * rightCounter,
      ease: Power3.easeOut,
    }).duration(1.4);
  }, [rightCounter, isHome]);

  useEffect(() => {
    if (isMobile || !isHome) {
      return;
    }
    const landingSection = document.getElementById('landing-section-container');
    const aboutSection = document.getElementById('about-section-container');
    const navigationSection = document.getElementById('navigation-section-container');
    const deviceSection = document.getElementById('device-section-container');
    const bookingSection = document.getElementById('booking-section-container');
    const carouselSection = document.getElementById('carousel-section-container');
    const awardSection = document.getElementById('award-section-container');
    const quoteSection = document.getElementById('quote-section-container');
    const footerSection = document.getElementById('footer-section-container');

    ScrollTrigger.create({
      trigger: landingSection,
      end: '30%',
      onToggle: ({ isActive }) => {
        if (isActive) {
          setRightCounter(0);
        }
      },
    });

    ScrollTrigger.create({
      trigger: aboutSection,
      onToggle: ({ isActive }) => {
        if (isActive) {
          setRightCounter(1);
        }
      },
    });

    ScrollTrigger.create({
      trigger: navigationSection,
      onToggle: ({ isActive }) => {
        if (isActive) {
          setRightCounter(2);
        }
      },
    });

    ScrollTrigger.create({
      trigger: deviceSection,
      end: '200%',
      onToggle: ({ isActive }) => {
        if (isActive) {
          setRightCounter(2);
        }
      },
    });

    ScrollTrigger.create({
      trigger: bookingSection,
      start: 'top',
      end: '450%',
      onToggle: ({ isActive }) => {
        if (isActive) {
          setRightCounter(3);
        }
      },
    });

    ScrollTrigger.create({
      trigger: carouselSection,
      start: 'top',
      end: '200%',
      onToggle: ({ isActive }) => {
        if (isActive) {
          setRightCounter(4);
        }
      },
    });

    ScrollTrigger.create({
      trigger: awardSection,
      start: '-50%',
      end: '50%',
      onToggle: ({ isActive }) => {
        if (isActive) {
          setRightCounter(5);
        }
      },
    });

    ScrollTrigger.create({
      trigger: quoteSection,
      start: 'top',
      end: '100%',
      onToggle: ({ isActive }) => {
        if (isActive) {
          setRightCounter(6);
        }
      },
    });

    ScrollTrigger.create({
      trigger: footerSection,
      start: '-10%',
      // end: '50%',
      // markers: true,
      onToggle: ({ isActive }) => {
        if (isActive) {
          setRightCounter(7);
        }
      },
    });
  }, [isMobile, isHome]);

  if (isHome) {
    return (
      <>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: -10,
          }}
        >
          <div
            style={{
              height: '100vh',
              position: 'sticky',
              top: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#F5F5F5',
            }}
          >
            <img src={Thunder} alt="" />
          </div>
        </div>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            zIndex: 900,
            pointerEvents: 'none',
            mixBlendMode: 'difference',
            padding: '0 80px',
          }}
        >
          {!isMobile && (
            <div
              style={{
                position: 'sticky',
                top: '50%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                style={{ position: 'absolute', left: 0, transform: 'translateY(-50%)' }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: boxWidth,
                    overflow: 'hidden',
                  }}
                >
                  <div style={{ display: 'flex' }}>
                    <div
                      className="paragraph color-white"
                      style={{
                        writingMode: 'vertical-rl',
                        textAlign: 'center',
                        width: boxWidth,
                        justifyContent: 'center',
                        alignItems: 'center',
                        transform: 'rotate(180deg)',
                      }}
                    >
                      {data && data.left_title}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{ position: 'absolute', right: 0, transform: 'translateY(-50%)' }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: boxWidth,
                    overflow: 'hidden',
                  }}
                >
                  <div style={{ display: 'flex' }} ref={textContainerRight}>
                    {data &&
                      data.right_section_title_group.map((item, index) => {
                        return (
                          <div
                            className="paragraph color-white"
                            style={{
                              writingMode: 'vertical-rl',
                              textAlign: 'center',
                              width: boxWidth,
                              justifyContent: 'center',
                              alignItems: 'center',
                              transform: 'rotate(180deg)',
                            }}
                            key={index}
                          >
                            {item.right_section_title}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return '';
  }
};

export default LayoutBackground;
