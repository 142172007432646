import React from 'react';
import Close from '../assets/img/close-icon.svg';
import Thunder from '../assets/img/thunder.svg';
import CargeButton from './CargeButton';

const MobileMenu = ({
  onClose,
  data,
  homeHeader,
  open,
  style,
  handleNavigation,
  active,
}) => {
  return (
    <div
      className="mobile-menu-container"
      style={{
        transform: open ? 'translateX(0%)' : 'translateX(100%)',
        transition: 'all 0.4s ease-in-out ',
      }}
    >
      <div className="mobile-menu-upper-container">
        <img src={Thunder} alt="" className="mobile-menu-image" />
        <div
          style={style}
          onClick={() => handleNavigation(0)}
          className={`title mobile-menu-text ${active === 0 && 'mobile-menu-active'}`}
        >
          {homeHeader}
        </div>
        {data?.header_tabs?.map((item, index) => {
          return (
            <div
              style={style}
              onClick={() => handleNavigation(index + 1)}
              className={`title mobile-menu-text ${
                active === index + 1 && 'mobile-menu-active'
              }`}
              key={index}
            >
              {item.header_tab?.charAt(0).toUpperCase() + item.header_tab?.slice(1)}
            </div>
          );
        })}
      </div>
      <div className="mobile-menu-bottom-container">
        <CargeButton
          className="mobile-menu-button"
          style={{ ...style, backgroundColor: '#00FFA3' }}
          href={data?.button_link?.url}
        >
          <div className="mobile-menu-button-text">{data?.button_text}</div>
        </CargeButton>
      </div>
      <div className="mobile-menu-close-icon" onClick={onClose}>
        <img src={Close} alt="" />
      </div>
    </div>
  );
};

export default MobileMenu;
