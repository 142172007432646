import React, { useRef } from 'react';
import CargeButton from './CargeButton';
import Background from '../assets/img/footer-background.svg';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { navigate } from 'gatsby';
import { Formik, Field, Form } from 'formik';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import ReactGA from 'react-ga';

const Footer = ({ data, lang }) => {
  gsap.registerPlugin(ScrollTrigger);

  const footerMainRef = useRef();
  const footerContentRef = useRef();

  const handleDetailClick = (index) => {
    const locale = lang === 'en' ? '' : '/' + lang;
    switch (index) {
      case 0:
        navigate(`${locale}/work-with-us`);
        break;
      case 1:
        navigate(`${locale}/help-center`);
        break;
      case 2:
        navigate(`${locale}/privacy`);
        break;
      case 3:
        navigate(`${locale}/tos`);
        break;
      case 4:
        navigate(`${locale}/cookies`);
        break;
      case 5:
        navigate(`${locale}/disclaimer`);
        break;
      default:
        break;
    }
  };

  const handleGa = (index) => {
    if (index === 0) {
      ReactGA.ga('send', 'event', 'Download', 'click', 'App Store 2');
      return;
    }
    ReactGA.ga('send', 'event', 'Download', 'click', 'Google Play 2');
  };

  return (
    <div style={{ minHeight: '100vh' }}>
      <div
        style={{
          backgroundColor: '#110935',
          height: '100vh',
          display: 'flex',
          scrollSnapAlign: 'start',
        }}
        ref={footerMainRef}
        id="footer-section-container"
      >
        <div
          ref={footerContentRef}
          className="footer-main-container container-xl"
          style={{
            backgroundImage: `url(${Background})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center left',
            padding: 48,
            maxWidth: 1100,
          }}
        >
          <div className="footer-top-container">
            <div className="footer-contact-container">
              {data?.contact_info_group?.map((item, index) => (
                <div className="footer-contact" key={index}>
                  <img
                    src={item.contact_info_image.url}
                    style={{ marginRight: 8 }}
                    alt=""
                  />
                  <div className="body color-white">{item.contact_info_text}</div>
                </div>
              ))}
            </div>
            <div className="footer-social-container">
              {data?.social_group?.map((item, index) => (
                <a
                  href={item.social_link.url}
                  className="a-tag-no-style d-flex"
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <img
                    src={item.social_image.url}
                    alt={''}
                    className={index === 0 ? 'footer-social' : ''}
                  />
                </a>
              ))}
            </div>
          </div>
          <div className="footer-middle-container">
            <div
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              {data?.title?.split('\n').map((item, index) => (
                <div className="h2 color-white" key={index}>
                  {item}
                </div>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-end',
                flex: 1,
              }}
            >
              {data &&
                data.get_the_app_group.map((item, index) => (
                  <CargeButton
                    outlined
                    icon={item?.button?.document?.data?.icon?.url}
                    link={item?.button?.document?.data?.link?.url}
                    style={{
                      color: 'white',
                      borderRadius: 16,
                      padding: '32px 24px',
                      marginBottom: 32,
                      width: 200,
                    }}
                    onClick={() => handleGa(index)}
                    key={index}
                  >
                    <div style={{ fontSize: 19 }}>
                      {item?.button?.document?.data?.text}
                    </div>
                  </CargeButton>
                ))}
              <a href={data?.espa_pdf?.url} target="_blank" rel="noreferrer">
                <img
                  src={data?.espa_logo?.url}
                  alt=""
                  style={{ width: 200, cursor: 'pointer' }}
                />
              </a>
            </div>
          </div>
          <div className="footer-bottom-container">
            <div className="footer-bottom-inner-container">
              <div
                className="d-flex"
                style={{
                  flexWrap: 'wrap',
                  minWidth: '45%',
                  maxWidth: '45%',
                }}
              >
                {data?.details?.map((item, index) => (
                  <div
                    className="d-flex align-items-start flex-1"
                    style={{ cursor: 'pointer', marginRight: 0, marginBottom: 16 }}
                    onClick={() => handleDetailClick(index)}
                    key={index}
                  >
                    <img
                      src={item?.detail_image?.url}
                      alt=""
                      style={{ marginRight: 8 }}
                    />
                    <div className="body color-white" style={{ whiteSpace: 'nowrap' }}>
                      {item?.detail_text}
                    </div>
                  </div>
                ))}
              </div>
              <Formik
                initialValues={{ email: '' }}
                onSubmit={(values, { resetForm }) => {
                  addToMailchimp(values.email).then(() => {
                    resetForm();
                  });
                }}
              >
                {({ handleSubmit, handleReset }) => (
                  <Form
                    name="footer"
                    onSubmit={handleSubmit}
                    onReset={handleReset}
                    className="d-flex"
                  >
                    <div
                      className="footer-text-input"
                      style={{
                        marginRight: 16,
                      }}
                    >
                      <Field
                        type="email"
                        name="email"
                        autoComplete="on"
                        style={{
                          backgroundColor: 'transparent',
                          boxShadow: 'none',
                          border: '1px solid white',
                          borderRadius: 20,
                          height: 66,
                          width: 316,
                          padding: '0 16px',
                          userSelect: 'none',
                          color: 'white',
                        }}
                        placeholder={data?.text_input_placeholder}
                      />
                    </div>
                    <button
                      className="carge-button-container"
                      style={{
                        backgroundColor: '#00A499',
                        border: 0,
                        height: 66,
                        width: 204,
                        borderRadius: 20,
                      }}
                      type="submit"
                    >
                      <div className="body color-white" style={{ fontSize: 18 }}>
                        {data?.button_text}
                      </div>
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
