import React, { useEffect, useRef, useState } from 'react';
import CargeButton from './CargeButton';
import Burger from '../assets/img/burger.svg';
import Lang from '../assets/img/translation-mobile.svg';
import MobileMenu from './MobileMenu';
import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { navigate } from 'gatsby';
import LanguageMenuMobile from './LanguageMenuMobile';
import ReactGA from 'react-ga';

gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const HeaderMobile = ({ data, uri, currentLang, blogData, languages, homeHeader }) => {
  const [menu, setMenu] = useState(false);
  const [active, setActive] = useState(0);
  const [isBlog, setIsBlog] = useState(false);
  const [selectedBlogTab, setSelectedBlogTab] = useState(0);
  const [lang, setLang] = useState(false);
  const headerRefMobile = useRef();

  useEffect(() => {
    if (uri.includes('blog')) {
      setActive(2);
    } else {
      setActive(0);
    }
  }, [uri]);

  useEffect(() => {
    const splitUri = uri.split('/');
    if (splitUri.includes('blog')) {
      setIsBlog(true);
      if (!blogData) {
        return;
      }
      blogData.sections_group?.forEach((item, index) => {
        if (splitUri.includes(item.section.toLowerCase().replace(/ /g, '-'))) {
          setSelectedBlogTab(index);
        }
      });
      return;
    }
    setIsBlog(false);
  }, [uri, blogData]);

  // useEffect(() => {
  //   ScrollTrigger.create({
  //     trigger: headerRefMobile.current,
  //     start: '0px top',
  //     end: 99999,
  //     onUpdate: ({ scroller }) => {
  //       if (scroller.scrollY < 15 && !uri.includes('blog') && !uri.includes('404')) {
  //         setIsTrans(true);
  //       } else {
  //         setIsTrans(false);
  //       }
  //     },
  //   });
  // }, []);

  useEffect(() => {
    if (
      uri.includes('work-with-us') ||
      uri.includes('help-center') ||
      (currentLang === 'en'
        ? uri?.length === 1
        : uri?.split(currentLang)[1]?.length === 0)
    ) {
      headerRefMobile.current.style.backgroundColor = '#11093500';
      ScrollTrigger.create({
        id: 'headerColor',
        trigger: headerRefMobile.current,
        start: '50px top',
        end: 99999999,
        onUpdate: ({ scroller }) => {
          if (scroller.scrollY < 50) {
            headerRefMobile.current.style.backgroundColor = '#11093500';
          } else {
            headerRefMobile.current.style.backgroundColor = '#110935';
          }
        },
      });
    }

    return () => {
      if (
        uri.includes('work-with-us') ||
        uri.includes('help-center') ||
        (currentLang === 'en'
          ? uri?.length === 1
          : uri?.split(currentLang)[1]?.length === 0)
      ) {
        ScrollTrigger.getById('headerColor').kill(true);
        gsap.set(headerRefMobile.current, {
          clearProps: 'all',
        });
      }
    };
  }, [uri, currentLang]);

  const onClickMenu = () => {
    setLang(false);
    setMenu(!menu);
  };

  const onClickLang = () => {
    setLang(!lang);
  };

  const handleNavigation = (index) => {
    setMenu(false);
    setActive(index);
    switch (index) {
      case 0:
        currentLang === 'en' ? navigate('/') : navigate(`/${currentLang}/`);
        break;
      case 1:
        currentLang === 'en' ? navigate('/') : navigate(`/${currentLang}/`);
        gsap.to(window, {
          duration: 1,
          scrollTo: { y: '#about-section-container', offsetY: 80 },
        });
        break;
      case 2:
        currentLang === 'en' ? navigate('/blog') : navigate(`/${currentLang}/blog`);
        break;
      default:
        return null;
    }
  };

  const handleBlogHeaderClick = (section, index) => {
    if (!currentLang) {
      return;
    }
    setSelectedBlogTab(index);
    if (currentLang === 'en') {
      if (index === 0) {
        navigate(`/blog`);
        return;
      }
      navigate(`/blog/${section.toLowerCase().replace(/ /g, '-')}`);
      return;
    }
    if (index === 0) {
      navigate(`/${currentLang}/blog`);
      return;
    }
    navigate(`/${currentLang}/blog/${section.toLowerCase().replace(/ /g, '-')}`);
  };

  const handleGa = () => {
    ReactGA.ga('send', 'event', 'Download', 'click', 'GET THE APP');
  };

  return (
    <div className="header-outer-container-mobile">
      <div className={`header-overlay-mobile ${(menu || lang) && 'dark'}`} />
      <div ref={headerRefMobile} className="header-container-mobile">
        <div className="flex-1-start">
          <img
            src={data?.logo_mobile?.url}
            alt=""
            style={{ pointerEvents: 'auto', width: 40 }}
            onClick={() =>
              currentLang === 'en' ? navigate('/') : navigate(`/${currentLang}/`)
            }
          />
        </div>
        <div className="flex-1-center flex-2">
          <CargeButton
            style={{ backgroundColor: '#00A499', pointerEvents: 'auto', color: '#fff' }}
            href={data?.button_link?.url}
            onClick={() => handleGa()}
          >
            {data?.button_text}
          </CargeButton>
        </div>
        <div className="flex-1-around" style={{ pointerEvents: 'auto' }}>
          <img src={Lang} alt="" onClick={onClickLang} />
          <img src={Burger} alt="" onClick={onClickMenu} />
        </div>
      </div>
      {isBlog && (
        <div className="header-blog-container-mobile">
          <div className="header-blog-inner-container-mobile">
            {blogData &&
              blogData?.sections_group.map((item, index) => (
                <div
                  className="body color-text-dark"
                  style={
                    selectedBlogTab === index
                      ? {
                          fontWeight: 700,
                          color: '#00A499',
                          fontSize: 16,
                          width: 'fit-content',
                          whiteSpace: 'nowrap',
                          padding: '0px 21px',
                        }
                      : {
                          fontSize: 16,
                          width: 'fit-content',
                          whiteSpace: 'nowrap',
                          padding: '0px 21px',
                        }
                  }
                  key={index}
                  onClick={() => handleBlogHeaderClick(item.section, index)}
                >
                  {item.section}
                </div>
              ))}
          </div>
        </div>
      )}
      <MobileMenu
        onClose={onClickMenu}
        data={data}
        homeHeader={homeHeader}
        open={menu}
        style={{ pointerEvents: 'auto' }}
        handleNavigation={handleNavigation}
        active={active}
        setActive={setActive}
      />
      <LanguageMenuMobile
        onClose={onClickLang}
        isOpen={lang}
        currentLang={currentLang}
        languages={languages}
      />
    </div>
  );
};

export default HeaderMobile;
