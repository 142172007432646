import React, { useEffect, useRef, useState } from 'react';
import CargeButton from './CargeButton';
import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { navigate } from 'gatsby';
import LanguageDropdown from './LanguageDropdown';
import ReactGA from 'react-ga';

gsap.registerPlugin(CSSPlugin);
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const Header = ({ uri, data, blogData, currentLang, languages }) => {
  const headerRef = useRef();

  const [headerHeight, setHeaderHeight] = useState(72);
  const [isBlog, setIsBlog] = useState(false);
  const [selectedBlogTab, setSelectedBlogTab] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const splitUri = uri.split('/');
    const langOffset = currentLang === 'en' ? 3 : 4;
    if (splitUri.includes('blog') && splitUri.length - 1 < langOffset) {
      setHeaderHeight(72 * 2);
      setIsBlog(true);
      if (!blogData) {
        return;
      }
      blogData.sections_group?.forEach((item, index) => {
        if (splitUri.includes(item.section.toLowerCase().replace(/ /g, '-'))) {
          setSelectedBlogTab(index);
        }
      });
      return;
    }
    setHeaderHeight(72);
    setIsBlog(false);
  }, [uri, blogData]);

  useEffect(() => {
    const actionNav = gsap.to(headerRef.current, {
      id: 'tween1',
      y: `-=${headerHeight}`,
      duration: 0.5,
      ease: 'power2.in',
      paused: true,
    });

    ScrollTrigger.create({
      id: 'trigger1',
      trigger: headerRef.current,
      start: '10px top',
      end: 99999,
      onUpdate: ({ direction }) => {
        if (direction === -1) {
          actionNav.reverse();
          setModalOpen(false);
        }
        if (direction === 1) {
          actionNav.play();
          setModalOpen(false);
        }
      },
    });

    if (
      uri.includes('work-with-us') ||
      uri.includes('help-center') ||
      (currentLang === 'en'
        ? uri?.length === 1
        : uri?.split(currentLang)[1]?.length === 0)
    ) {
      headerRef.current.style.backgroundColor = '#11093500';
      ScrollTrigger.create({
        id: 'headerColor',
        trigger: headerRef.current,
        start: '150px top',
        end: 99999999,
        onUpdate: ({ scroller }) => {
          if (scroller.scrollY < 150) {
            headerRef.current.style.backgroundColor = '#11093500';
          } else {
            headerRef.current.style.backgroundColor = '#110935';
          }
        },
      });
      //***** gsap method - caused bugs ****/
      // gsap.to(
      //   headerRef.current,
      //   {
      //     y: '150px',
      //     ease: 'none',
      //     scrollTrigger: {
      //       id: 'headerColor',
      //       trigger: headerRef.current,
      //       start: '150px top',
      //       onUpdate:
      //     },
      //   }
      // );
    }

    return () => {
      ScrollTrigger.getById('trigger1').kill(true);
      gsap.set(headerRef.current, {
        clearProps: true,
      });
      if (
        uri.includes('work-with-us') ||
        uri.includes('help-center') ||
        (currentLang === 'en'
          ? uri?.length === 1
          : uri?.split(currentLang)[1]?.length === 0)
      ) {
        ScrollTrigger.getById('headerColor').kill(true);
      }
    };
  }, [uri, headerHeight, currentLang]);

  const handleHeaderClick = (index) => {
    switch (index) {
      case 0:
        currentLang === 'en' ? navigate('/') : navigate(`/${currentLang}/`);
        gsap.to(window, { duration: 1, scrollTo: '#about-section-container' });
        break;
      case 1:
        currentLang === 'en' ? navigate('/blog') : navigate(`/${currentLang}/blog`);
        break;
      default:
        return null;
    }
  };

  const handleBlogHeaderClick = (section, index) => {
    if (!currentLang) {
      return;
    }
    setSelectedBlogTab(index);
    if (currentLang === 'en') {
      if (index === 0) {
        navigate(`/blog`);
        return;
      }
      navigate(`/blog/${section.toLowerCase().replace(/ /g, '-')}`);
      return;
    }
    if (index === 0) {
      navigate(`/${currentLang}/blog`);
      return;
    }
    navigate(`/${currentLang}/blog/${section.toLowerCase().replace(/ /g, '-')}`);
  };

  const handleGa = () => {
    ReactGA.ga('send', 'event', 'Download', 'click', 'GET THE APP');
  };

  return (
    <div
      className={`header-container ${isBlog ? 'header-blog-height' : ''}`}
      style={{}}
      ref={headerRef}
    >
      <div className="header-main-container">
        <div
          className="flex-1-start"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            currentLang === 'en' ? navigate('/') : navigate(`/${currentLang}/`)
          }
        >
          {data && <img src={data.logo?.url} alt="" />}
        </div>
        <div className="flex-1-center">
          {data &&
            data.header_tabs.map((section, index) => {
              return (
                <div
                  className="subtitle color-white header-subtitle"
                  key={index}
                  style={{
                    cursor: 'pointer',
                    padding: 16,
                    color: isBlog && index === 1 ? '#00FFA3' : null,
                  }}
                  onClick={() => handleHeaderClick(index)}
                >
                  {section.header_tab}
                </div>
              );
            })}
        </div>
        <div className="flex-1-end">
          <LanguageDropdown
            currentLang={currentLang}
            languages={languages}
            uri={uri}
            open={modalOpen}
            setOpen={setModalOpen}
          />
          <CargeButton
            style={{ backgroundColor: '#00A499' }}
            href={data && data.button_link?.url}
            onClick={() => handleGa()}
          >
            {data && data.button_text && (
              <div style={{ textTransform: 'uppercase', color: '#fff' }}>
                {data.button_text}
              </div>
            )}
          </CargeButton>
        </div>
      </div>
      {isBlog && (
        <div className="header-blog-container">
          <div className="header-blog-inner-container">
            {blogData &&
              blogData?.sections_group.map((item, index) => (
                <div
                  className="body color-text-dark"
                  style={
                    selectedBlogTab === index
                      ? {
                          fontWeight: 700,
                          color: '#00A499',
                          cursor: 'pointer',
                          fontSize: 16,
                        }
                      : { cursor: 'pointer', fontSize: 16 }
                  }
                  key={index}
                  onClick={() => handleBlogHeaderClick(item.section, index)}
                >
                  {item.section}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
