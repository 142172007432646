import React from 'react';
import Background from '../assets/img/404.svg';
import BackgroundMobile from '../assets/img/404-mobile.svg';
import CargeButton from '../components/CargeButton';
import useBreakpoint from '../utils/useBreakpoint';
import { navigate } from 'gatsby';

const Page404 = () => {
  const isMobile = useBreakpoint('sm', false);

  const navigateHome = () => {
    navigate('/');
  };
  return (
    <div className="page404-container">
      <div className="left-container">
        <div className="left-inner-container">
          <div className="h3 title-container color-text-dark">
            Everything happens for a reason
          </div>
          <div className="title subtitle-container color-text-dark">
            You ran into a 404 error.
            <br />
            Try to refresh before unplugging
          </div>
          <div className="button-container">
            <CargeButton
              outlined
              className="button-404-dark cursor-pointer"
              onClick={navigateHome}
            >
              <div className="color-white paragraph">
                {isMobile ? 'BACK TO HOME' : 'GO TO HOMEPAGE'}
              </div>
            </CargeButton>
            <CargeButton outlined className="button-404-green cursor-pointer">
              <div className="color-text-dark paragraph">GET THE APP</div>
            </CargeButton>
          </div>
        </div>
      </div>
      <div className="image-container">
        <img src={isMobile ? BackgroundMobile : Background} alt="" className="image" />
      </div>
    </div>
  );
};

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // render fallback UI
      return <Page404 />;
    }

    // when there's not an error, render children untouched
    return this.props.children;
  }
}
