import React, { useEffect, useState } from 'react';
import Arrow from '../assets/img/language-arrow.svg';
import { navigate } from 'gatsby';
import { getLanguageNames } from '../utils/isoLang';

const LanguageDropdown = ({ languages, currentLang, open, setOpen, ...rest }) => {
  const [languageNames, setLanguageNames] = useState();
  const [currentLangName, setCurrentLangName] = useState('');

  useEffect(() => {
    if (!languages) {
      return;
    }
    const langNameArray = [];
    languages.forEach((lang) => {
      langNameArray.push(getLanguageNames(lang));
    });
    setLanguageNames(langNameArray);
  }, [languages]);

  useEffect(() => {
    if (!currentLang) {
      return;
    }
    const langName = getLanguageNames(currentLang);
    setCurrentLangName(langName);
  }, [currentLang]);

  const handleClick = (index) => {
    setOpen(false);
    if (languages[index] === 'en') {
      navigate('/');
    } else {
      navigate(`/${languages[index]}`);
    }
  };

  return (
    <div className="language-dropdown-container" {...rest}>
      <div className="language-dropdown-title-container" onClick={() => setOpen(!open)}>
        <div className="paragraph color-white language-dropdown-title">
          {currentLangName}
        </div>
        <img src={Arrow} alt="" />
      </div>
      {open && (
        <div className={`language-dropdown-list-container`}>
          <div className="paragraph color-white language-dropdown-list-title">
            Language:
          </div>
          {languageNames &&
            languageNames.map((language, index) => {
              return (
                <div
                  className={`body language-dropdown-list-item ${
                    language === currentLangName ? 'selected' : ''
                  }`}
                  onClick={() => handleClick(index)}
                  key={index}
                >
                  {language}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default LanguageDropdown;
